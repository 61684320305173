<template>
  <legend :class="['legend', `legend-${type}`, `legend-size-${size}`]">
    <SvgIcon
      v-if="size === 'default'"
      class="legend-icon"
      :icon="ICON_TYPE_MAP[type]"
    />
    {{ label || TYPE_TRANSLATIONS[type] }}
  </legend>
</template>

<script lang="ts" setup>
  const ICON_TYPE_MAP = {
    business: "business",
    culture: "smile",
    inspiration: "bulb",
    news: "news",
    product: "shopping-bag",
    candle: "candle",
    soap: "soap",
  }

  const TYPE_TRANSLATIONS = {
    business: "Business",
    culture: "Culture",
    inspiration: "Inspiration",
    news: "News",
    product: "Product",
    candle: "Candle Making",
    soap: "Soap Making",
  }

  withDefaults(
    defineProps<{
      type:
        | "canlde"
        | "soap"
        | "product"
        | "news"
        | "inspiration"
        | "culture"
        | "business"
      label?: string
      size?: "default" | "small"
    }>(),
    {
      label: "",
      size: "default",
    },
  )
</script>

<style lang="scss" scoped>
  .legend {
    display: flex;
    padding: 0;
    align-items: center;
    font-weight: bold;
    &-size-default {
      @include type("h4");
    }
    &-size-small {
      @include type("h6");
    }
    &-product {
      color: $blue-text;
    }
    &-candle {
      color: $yellow-text;
    }
    &-soap {
      color: $green-text;
    }
    &-business {
      color: $purple-text;
    }
    &-inspiration {
      color: $yellow-text;
    }
    &-culture {
      color: $red-text;
    }
    &-news {
      color: $navy-text;
    }
  }

  .legend-icon {
    margin-right: $base-spacing * 2;
    stroke-width: 2;
    width: $icon;
    height: $icon;
  }
</style>
