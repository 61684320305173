<template>
  <NuxtLink :to="href" class="article">
    <div class="article-image-wrap">
      <picture>
        <source
          v-for="imgType in createFormatArray(srcSet)"
          :key="imgType.type"
          :srcset="imgType.srcsetstring"
          sizes="100px"
        />
        <img class="article-image" :src="src" :alt="alt" loading="lazy" />
      </picture>
    </div>
    <div class="article-content">
      <ArticleLegend size="small" :type="legend" :label="customType" />
      <span class="article-title">
        {{ title }}
      </span>
    </div>
  </NuxtLink>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    mixins: [createFormatArray],

    props: {
      legend: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      href: {
        type: String,
        required: true,
      },
      alt: {
        type: String,
        default: "",
      },
      src: {
        type: String,
        required: true,
      },
      srcSet: {
        type: Array,
        default: () => [],
      },
      customType: {
        type: String,
        default: "",
      },
    },
  }
</script>

<style lang="scss" scoped>

  .article {
    display: flex;
    justify-content: flex-start;
    grid-gap: $gap;
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      .article-title {
        text-decoration: underline;
      }
      .article-image {
        transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
        box-shadow: $hover-shadow;
      }
    }
  }
  .article-content {
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  .article-title {
    @include type('h5');
  }
  .article-image-wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .article-image {
    aspect-ratio: 10/9;
    width: 100px;
    border-radius: $border-radius;
    height: 100%;
    object-fit: cover;
    box-shadow: $shadow;
    transition: transform 120ms ease-in-out, box-shadow 120ms ease-in-out;
    transform-origin: 50% 50%;
    will-change: transform, box-shadow;
    &:hover {
      transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
      box-shadow: $hover-shadow;
    }
  }
</style>
